import React, {useState, useCallback} from 'react';
import {Stack, TextContainer, ColorPicker, Button, TextField, TextStyle, Popover} from '@shopify/polaris';
import './styles.css';

function hsbToRgb({ hue, saturation, brightness }) {
  const h = hue / 360;
  const s = saturation;
  const v = brightness;

  let r, g, b;

  const i = Math.floor(h * 6);
  const f = h * 6 - i;
  const p = v * (1 - s);
  const q = v * (1 - f * s);
  const t = v * (1 - (1 - f) * s);

  switch (i % 6) {
    case 0: r = v; g = t; b = p; break;
    case 1: r = q; g = v; b = p; break;
    case 2: r = p; g = v; b = t; break;
    case 3: r = p; g = q; b = v; break;
    case 4: r = t; g = p; b = v; break;
    case 5: r = v; g = p; b = q; break;
  }

  return {
    red: Math.round(r * 255),
    green: Math.round(g * 255),
    blue: Math.round(b * 255)
  };
}

// Helper function to convert RGB to Hex
function rgbToHex({ red, green, blue }) {
  const toHex = (value) => {
    const hex = value.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  return `#${toHex(red)}${toHex(green)}${toHex(blue)}`;
}

const CustomColorPicker = (props) => {
  const {title} = props;
  const [color, setColor] = useState({
    hue: 222,
    brightness: 0.96,
    saturation: 0.45,
    alpha: 1
  });
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );

  const handleColorChange = useCallback((color) => {
    setColor(color);
    setPopoverActive(false);
  }, []);

  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      Edit
    </Button>
  );

  return (
    <Stack vertical spacing="tight">
      <TextContainer spacing="loose">
        <TextStyle>{title}</TextStyle>
      </TextContainer>
      <Stack alignment="center">
        <div
          style={{
            height: '36px',
            width: '36px',
            borderRadius: '4px',
            background: `hsl(${color.hue}, ${color.saturation * 100}%, ${color.brightness * 100}%)`,
          }}
        />
        <Stack.Item fill>
          <TextField
            width="100%"
            value={rgbToHex(hsbToRgb(color))}
            readOnly
            autoComplete="off"
          />
        </Stack.Item>
        <Popover
          active={popoverActive}
          activator={activator}
          onClose={togglePopoverActive}
          ariaHaspopup={false}
          sectioned
        >
          <ColorPicker onChange={handleColorChange} color={color} />
        </Popover>
      </Stack>
    </Stack>
  );
};

CustomColorPicker.propTypes = {};

export default CustomColorPicker;